import { message } from "antd";
import "./form.scss";
import { useRef, useState } from "react";
import { useEffect } from "react";
const FormUser = (props) => {
  const { setModalFinalResult, handleSetModalButton, handleQuay, result } =
    props;
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const refName = useRef(null);
  const refPhone = useRef(null);
  const [initcountry, setCountry] = useState("");

  const handleSetName = (e) => {
    setName(e.target.value);
  };
  const handleSetPhone = (e) => {
    setPhone(e.target.value);
  };
  const callAPI = () => {
    const dataBody = {
      code: "7268361002",
      name: name,
      phone: phone,
      prize: result,
      sex: "",
      address: "",
    };
    console.log(dataBody);
    fetch("https://tmbranding.vn/Woay/API/add.php", {
      method: "POST",
      body: JSON.stringify(dataBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("check result api: ", result);

        if (result && result["message"] === "already") {
          message.error("Số điện thoại đã được dùng");
        } else if (result && result["message"] === "success") {
          //localStorage.setItem("TMWheel", "OK");
          setModalFinalResult(true);
          handleSetModalButton(true);
          handleQuay(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const isVietnamesePhoneNumber = (number) => {
    return /(03|05|07|08|09)+([0-9]{8})\b/.test(number);
  };
  const checkForm = async () => {
    if (!name) {
      refName.current.focus();
      refName.current.style.border = "2px solid red";
    } else {
      refName.current.style.border = "none";
    }
    if (!phone) {
      refPhone.current.focus();
      refPhone.current.style.border = "2px solid red";
      return;
    } else {
      refPhone.current.style.border = "none";
    }
    if (!isVietnamesePhoneNumber(phone)) {
      message.error("Số điện thoại không đúng");
      return;
    }

    if (name && phone) {
      callAPI();

      // setModalFinalResult(true);
      // handleSetModalButton(true);
      // handleQuay(false);

      // fetch("https://api.ipify.org")
      //   .then((res) => res.text())
      //   .then((ip) => console.log("check ip là .....", ip));

      // fetch("http://ip-api.com/json/?fields=61439") cần đăng kí lấy https
      //   .then((res) => res.json())
      //   .then((location) => console.log("dia chi chi tiet la: ", location));

      // localStorage.setItem("TMWheel", "OK");
    }
  };
  // const ApiGetLocation = async (ip) => {
  //   try {
  //     const response = await fetch(
  //       `https://api.ipdata.co/${ip}?api-key=310a7c99d91e3bc598cb9846bf052c97829cd558018c55d65b17c2aa`
  //     );
  //     const data = await response.json();
  //     console.log(data);
  //     setCountry(data.city);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const ApiGetIp = async () => {
  //   try {
  //     const response = await fetch("https://api.ipify.org");
  //     const data = await response.text();

  //     console.log("dia chi ip", data);
  //     await ApiGetLocation(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // useEffect(() => {
  //   ApiGetIp();
  // }, []);

  return (
    <div className="form">
      <input
        placeholder="Họ và tên"
        className="field"
        ref={refName}
        onChange={(e) => {
          handleSetName(e);
        }}
      ></input>

      <input
        placeholder="Số điện thoại"
        className="field"
        ref={refPhone}
        onChange={(e) => {
          handleSetPhone(e);
        }}
      ></input>
      <div className="btn-ok" onClick={checkForm}>
        Xác Nhận
      </div>
    </div>
  );
};

export default FormUser;
